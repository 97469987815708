import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderService } from 'src/app/global/header/header.service';
import { IngrammicroService } from 'src/app/ingrammicro.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';

@Component({
  selector: 'app-pwrequest',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent implements OnInit {  
  showForm: Boolean = true;
  showSuccess: Boolean = false;
  showFailure: Boolean = false;
  showMissingEmail: Boolean = false;
  email: String = null;

  showCaptchaValidated: boolean = false;
  captchaValidationSiteKey: string = "";
  captchaSuccessToken: string;
  aFormGroup: FormGroup;

  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;

  constructor(private headerService: HeaderService, private ingrammicro: IngrammicroService, private formBuilder: FormBuilder) {
    this.captchaValidationSiteKey = ingrammicro.captchaValidationSiteKey
  }

  ngOnInit() {
    this.aFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required]
    });

    if(localStorage.getItem('recaptcha')) this.captchaSuccessToken = localStorage.getItem('recaptcha');
  }

  closePasswordReset(){
    this.headerService.setShowPasswordReset(false);
  }

  onRecaptchaSuccess(e){
      this.showCaptchaValidated = true;
      this.captchaSuccessToken = e;
      localStorage.setItem('recaptcha', this.captchaSuccessToken);
      localStorage.setItem('recaptcha_expiry', Date.now() + (30 * 60 * 1000) + '');
  }

  onReCaptchaReset() {
    this.captchaElem.reloadCaptcha();
    this.showCaptchaValidated = false;
    this.captchaSuccessToken = undefined;
  }

  requestPasswordReset(){
    this.showMissingEmail = false;
    
    if(this.email != null){
      this.ingrammicro.createRequest('POST', '/api/Registration/RequestPasswordReset?Username='+this.email).subscribe(response => {
        if(response == true){
          this.showForm = false;
          this.showSuccess = true;
        } else {
          this.showForm = false;
          this.showFailure = true;
        }
      });
    } else {
      this.showMissingEmail = true;
    }
  }
}
