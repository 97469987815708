import { Injectable, ChangeDetectorRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import {catchError, mergeMap} from 'rxjs/operators';
import { from } from 'rxjs';
import { HeaderService } from './global/header/header.service';


@Injectable({
  providedIn: 'root'
})
export class IngrammicroService {
  
  // api_host = 'https://three-voice-api.devicetradein.co.uk';
  // api_host = 'https://ccrecycle-dev-api.devicetradein.co.uk';

  api_host = window.location.origin.includes("ccrecycle.three.co.uk")
  ? "https://three-voice-api.devicetradein.co.uk"
  : "https://ccrecycle-dev-api.devicetradein.co.uk";

  captchaValidationSiteKey: string = "6LcOnaoaAAAAAHAfSTxdmxb01LAQ1cplC2kl5-cC";

  response: Object = null;

  constructor(private http: HttpClient, private headerService: HeaderService) { }

  createRequest(type = 'GET', url, payload = null): Observable<any>{
    return this.getData(type, url, payload).pipe(catchError(err=> {
      if (err['status'] == 429 || err['status'] == 403) {
        this.deleteCookie('token')
        this.headerService.setShowAPIBlockPopup(err['error']['message'])
      }
      return of(null);
    }))
  }

  getData(type = 'GET', url, payload = null){
    var recaptcha = '';
    var token = this.getCookie('token');
    if(localStorage.getItem('recaptcha')) recaptcha += localStorage.getItem('recaptcha');
    var headers = {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/x-www-form-urlencoded',
      'recaptcha': recaptcha
    }
    if(type == 'GET'){
      return this.http.get(this.api_host+url, {headers: headers});
    } else if(type == 'POST') {
      return this.http.post(this.api_host+url, payload, {headers: headers});
    } else {
      return null;
    }
  }

  getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  deleteCookie(cname){
    document.cookie = cname + "=null; expires=expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/";
  }
}
