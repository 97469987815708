import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as jQuery from 'jquery';
import { HeaderService } from 'src/app/global/header/header.service';


@Component({
	selector: 'app-payment',
	templateUrl: './payment.component.html',
	styleUrls: ['../cart.component.scss', './payment.component.scss']
})
export class PaymentComponent implements OnInit {

	cartContents: Array<any> = [];
	totalCheckout: number = null;
	selectedPayment: String = null;
	showPopup: Boolean = false;
	showBillPopup: Boolean = false;
	showPaypalPopup: Boolean = false;
	billTelephone: String = null;
	paypalemail: String = null;
	billingCheckbox: String = null;
  showContinueError: boolean = false;

  promoCode: string = '';
  promoValue: number = 0;

	constructor(private router: Router, private headerService: HeaderService) { }

	ngOnInit() {

		this.headerService.setShowHeader(false);

		if (!localStorage.getItem("cart")) {
			this.router.navigateByUrl('/cart');
		}

		if (localStorage.getItem("cart")) {
			this.cartContents = JSON.parse(localStorage.getItem("cart"));

			for (let item of this.cartContents) {
				this.totalCheckout = this.totalCheckout + (item.quantity * item.price);
			}

    }

    if (localStorage.getItem("promo")) {
      this.promoValue = JSON.parse(localStorage.getItem("promo"))['value'];
      this.promoCode = JSON.parse(localStorage.getItem("promo"))['code'];
    }

		jQuery(document).scroll(function () {
			var scrolled = (jQuery(document).scrollTop() / 2);
			jQuery('.anim-banner').css('background-position', 'center ' + scrolled + 'px');
		});
	}

	selectMethod(method) {
		this.selectedPayment = method;
	}

	checkoutContinue() {
		if (this.selectedPayment != null) {
			this.showContinueError = false;
			if (this.selectedPayment == "banktransfer") {
				localStorage.setItem('payment', JSON.stringify({ "paymentType": 2 }));
				this.router.navigateByUrl('/cart/bankdetails');
			} else if (this.selectedPayment == "cheque") {
				localStorage.setItem('payment', JSON.stringify({ "paymentType": 1 }));
				this.router.navigateByUrl('/cart/delivery');
			} else if (this.selectedPayment == "billcredit") {
				this.openBillPopup();
			} else if (this.selectedPayment == "paypal") {
				this.openPaypalPopup();
			} else {
				console.error('Invalid payment method selected');
			}
		} else {
			this.showContinueError = true;
		}
	}

	openChequePopup() {
		this.showPopup = true;
		this.showBillPopup = true;
	}

	confirmChequeDetails() {
		localStorage.setItem('payment', JSON.stringify({ "paymentType": 1 }));
		this.router.navigateByUrl('/cart/delivery');
	}

	openBillPopup() {
		this.showPopup = true;
		this.showBillPopup = true;
	}

	confirmBillDetails() {
		localStorage.setItem('payment', JSON.stringify({ "paymentType": 9, "ThreeNumber": this.billTelephone }));
		this.router.navigateByUrl('/cart/delivery');
	}

	openPaypalPopup() {
		this.showPopup = true;
		this.showPaypalPopup = true;
	}

	confirmPaypalDetails(){
		localStorage.setItem('payment', JSON.stringify({ "paymentType": 13, "Paypal": this.paypalemail }));
		this.router.navigateByUrl('/cart/delivery');
	}

	closePopup(){
		this.showPopup = false;
		this.showBillPopup = false;
		this.showPaypalPopup = false;
	}

	toggleBillingCheckbox(){
		if(this.billingCheckbox == 'active'){
			this.billingCheckbox = null;
		} else {
			this.billingCheckbox = 'active';
		}
	}
}
