import { Component, OnInit, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import * as jQuery from 'jquery';
import { HeaderService } from '../global/header/header.service';
import { IngrammicroService } from '../ingrammicro.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss']
})
export class CartComponent implements OnInit {

	cartContents: Array<any> = [];
	totalCheckout: number = null;
	isLoggedIn: Boolean = false;
  loggedInUser: Object = null;

  promoValue: number = 0;
  promoCode: string = '';
  validPromo: boolean = false;
  validationMessage: String = "";

  constructor(private router: Router, private route: ActivatedRoute, private headerService: HeaderService, private ingrammicro: IngrammicroService) { }

  ngOnInit() {

    this.headerService.setShowHeader(false);

    this.getCart();

    jQuery(document).scroll(function() {
      var scrolled = (jQuery(document).scrollTop() / 2);
      jQuery('.anim-banner').css('background-position','center '+scrolled+'px');
    });

    if(localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))['CustomerID'] > 0){
      this.isLoggedIn = true;
      this.loggedInUser = JSON.parse(localStorage.getItem("user"));
    }
  }

  clearCart(){
	  localStorage.setItem('cart',"");
	  this.getCart();
  }

  getCart(){
	  if(localStorage.getItem("cart")){
		this.cartContents = JSON.parse(localStorage.getItem("cart"));

		for(let item of this.cartContents){
			this.totalCheckout = this.totalCheckout + (item.quantity * item.price);
		}

	  } else {
		  this.cartContents = [];
		  this.totalCheckout = null;
	  }
  }

  checkPromo() {
		if (this.promoCode) {
      if(this.promoCode.length != 15) {
        this.validationMessage = "Invalid Voucher Code"
          } else if (this.cartContents[0]['basketId']) {
          this.ingrammicro.createRequest('POST', '/api/Voucher/ValidateVoucher', 'VoucherCode=' + this.promoCode + '&BasketID=' + this.cartContents[0]['basketId']).subscribe(response => {
            if (response['IsValid'] == true) {
                //Add it immediately
                this.promoValue = response['CashValue'];
                this.verifyPromo();
                this.validPromo = true;
                this.validationMessage = "";
                // localStorage.setItem('promo', JSON.stringify({ code: this.promoCode, value: this.promoValue }));
            } else {
                this.validationMessage = response['MessageText'];
                // testing hardcoded value for frontend display
                // this.validationMessage = '';
                // this.validPromo = true;
                // this.promoValue = 10;
                // localStorage.setItem('promo', JSON.stringify({ code: this.promoCode, value: this.promoValue }));
              }
          });
        } else {
              this.validationMessage = 'No items are in the basket';
        }
      } else {
        this.validationMessage = "Please enter a voucher code";
      }
  }

  verifyPromo() {
    this.ingrammicro.createRequest('POST', '/api/Voucher/AddVoucher', 'VoucherCode=' + this.promoCode + '&BasketID=' + this.cartContents[0]['basketId'] + '&ValidationField=' + null).subscribe(response => {
				if (response['Success'] == true) {
					//success
          this.validPromo = true;
					localStorage.setItem('promo', JSON.stringify({ code: this.promoCode, value: this.promoValue }));
				} else {
          //error
				}
			});
  }

  progressCart(){
	this.router.navigateByUrl('/cart/details');
  }

}
