import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';

import * as jQuery from 'jquery';
import { IngrammicroService } from 'src/app/ingrammicro.service';
import { HeaderService } from 'src/app/global/header/header.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecaptchaService } from 'src/app/recaptcha.service';

@Component({
	selector: 'app-details',
	templateUrl: './details.component.html',
	styleUrls: ['../cart.component.scss', './details.component.scss']
})

export class DetailsComponent implements OnInit {

	addressLine1: String = null;
	addressLine2: String = null;
	addressTown: String = null;
	addressPostcode: string = null;
	addressResults: Array<{ "addressLine2": string, "addressTown": string, "addressPostcode": string }> = [];
	addressesShown: boolean = false;
	showAddressFinder:boolean = false;
	searchQuery: String = null;
	isLoggedIn: Boolean = false;
	loggedInUser: Object = null;
	autofill: Boolean = false;
	cartContents: Array<any> = [];
	totalCheckout: number = null;

	userAddressLine1: String = null;
	userAddressLine2: String = null;
	userAddressTown: String = null;
	userAddressPostcode: String = null;

	checkoutFirstname: String = null;
	checkoutLastname: String = null;
	checkoutPhone: String = null;
	checkoutEmail: String = null;
	checkoutEmailConf: String = null;
	checkoutPassword: String = null;
	checkoutPasswordConf: String = null;

	accountPasswordShown: boolean = false;
	confirmPasswordShown: boolean = false;
	showFormError: boolean = false;
	apiError: String = '';

	promoCode: string = '';
	promoValue: number = 0;

	showCaptchaValidated: boolean = false;
	captchaValidationSiteKey: string = "";
	captchaSuccessToken: string;
  
	aFormGroup: FormGroup;
  
	@ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;
  
	onRecaptchaSuccess(e){
	  this.showCaptchaValidated = true;
	  this.captchaSuccessToken = e;
	}

	constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private headerService: HeaderService, private ingrammicro: IngrammicroService, private formBuilder: FormBuilder, private recaptchaService: RecaptchaService) {
		this.captchaValidationSiteKey = ingrammicro.captchaValidationSiteKey
	}
	
	ngOnInit() {
		this.aFormGroup = this.formBuilder.group({
		  recaptcha: ['', Validators.required]
		});

		this.headerService.setShowHeader(false);

		if (!localStorage.getItem("cart")) {
			this.router.navigateByUrl('/cart');
		}

		if (localStorage.getItem("cart")) {
			this.cartContents = JSON.parse(localStorage.getItem("cart"));
			for (let item of this.cartContents) {
				this.totalCheckout = this.totalCheckout + (item.quantity * item.price);
			}
		}

		if (localStorage.getItem("promo")) {
		this.promoValue = JSON.parse(localStorage.getItem("promo"))['value'];
		this.promoCode = JSON.parse(localStorage.getItem("promo"))['code'];
		}

		jQuery(document).scroll(function () {
			var scrolled = (jQuery(document).scrollTop() / 2);
			jQuery('.anim-banner').css('background-position', 'center ' + scrolled + 'px');
		});

		if (localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"))['CustomerID'] > 0) {
			this.isLoggedIn = true;
			this.loggedInUser = JSON.parse(localStorage.getItem("user"));
			console.log(this.loggedInUser);

			if (!localStorage.getItem("newAddress")) {
				var existingAddress = JSON.parse(localStorage.getItem("user"))['PackDeliveryAddress'];
				this.userAddressLine1 = existingAddress['Address1'];
				this.userAddressLine2 = existingAddress['Address2'];
				this.userAddressTown = existingAddress['City'];
				this.userAddressPostcode = existingAddress['PostCode'];
			} else {
				var newAddress = JSON.parse(localStorage.getItem("newAddress"));
				this.userAddressLine1 = newAddress['line1'];
				this.userAddressLine2 = newAddress['line2'];
				this.userAddressTown = newAddress['town'];
				this.userAddressPostcode = newAddress['postcode'];
			}
		}
	}

	getAddresses() {
		this.http.get('https://api.ideal-postcodes.co.uk/v1/postcodes/' + this.searchQuery + '/?api_key=ak_jwgh8ozzaKXDP2OQgKxvF7rjQdulN').subscribe(response => {
			this.addressResults = response['result'];
			jQuery('.address-finder-results').css('border-bottom-width', '1px');
			this.addressesShown = true;
		});
	}

	selectAddress(addressLine1, addressLine2, addressTown, addressPostcode) {
		this.addressLine1 = addressLine1;
		this.addressLine2 = addressLine2;
		this.addressTown = addressTown;
		this.addressPostcode = addressPostcode;
		this.addressResults = [];
		this.autofill = true;
		jQuery('.address-finder-form').css('display', 'none');
		jQuery('.address-finder-results').css('border-bottom-width', '0');
		jQuery('.logged-in-address').hide();
		jQuery(".address-error").hide();
		jQuery(".autofill-address").hide();
		jQuery(".autofill-address").fadeIn();
	}

	logOut() {
		this.isLoggedIn = false;
		this.loggedInUser = null;
		this.addressResults = [];
		this.addressLine1 = null;
		this.addressLine2 = null;
		this.addressTown = null;
		this.addressPostcode = null;
		this.checkoutFirstname = null;
		this.checkoutLastname = null;
		this.checkoutPhone = null;
		this.checkoutEmail = null;
		this.checkoutEmailConf = null;
		this.checkoutPassword = null;
		this.checkoutPasswordConf = null;
		this.autofill = false;
		jQuery(".autofill-address").hide();
		jQuery('.address-finder-form').css('display', 'block');
		localStorage.setItem('user', "");
	}


	showAutofill() {
		jQuery(".autofill-address").hide();
		jQuery(".autofill-address").fadeIn();
		jQuery('.logged-in-address').hide();
		this.autofill = true;
		this.addressResults = [];
		this.addressLine1 = null;
		this.addressLine2 = null;
		this.addressTown = null;
		this.addressPostcode = jQuery(".address-finder-form input").val().toString();
	}

	toggleAccountPassword () {
		this.accountPasswordShown = !this.accountPasswordShown;
	}

	toggleConfirmPassword () {
		this.confirmPasswordShown = !this.confirmPasswordShown;
	}

	continueCheckout() {
		if (this.isLoggedIn == false) {
			var errors = 0;
			jQuery(".field-error").remove();
			jQuery(".details-field-error").remove();

			if (!this.checkoutFirstname) {
				jQuery(".checkout-firstname").after("<div class='details-field-error'>Please fill in the above field</div>");
				errors = 1;
			}
			if (!this.checkoutLastname) {
				jQuery(".checkout-lastname").after("<div class='details-field-error'>Please fill in the above field</div>");
				errors = 1;
			}
			if (!this.checkoutPhone) {
				jQuery(".checkout-phone").after("<div class='details-field-error'>Please fill in the above field</div>");
				errors = 1;
			}
			if (!this.checkoutEmail) {
				jQuery(".checkout-email").after("<div class='details-field-error'>Please fill in the above field</div>");
				errors = 1;
			}
			if (!this.checkoutEmailConf) {
				jQuery(".checkout-emailconf").after("<div class='details-field-error'>Please fill in the above field</div>");
				errors = 1;
			} else if (this.checkoutEmailConf != this.checkoutEmail) {
				jQuery(".checkout-emailconf").after("<div class='details-field-error'>This does not match the above Email address</div>");
				errors = 1;
			}

			if (!this.checkoutPassword) {
				jQuery(".checkout-password").after("<div class='details-field-error'>Please fill in the above field</div>");
				errors = 1;
			} else if (this.checkoutPassword.length < 6) {
				jQuery(".checkout-password").after("<div class='details-field-error'>Please make sure your password is at least 6 characters</div>");
				errors = 1;
			}

			if (!this.checkoutPasswordConf) {
				jQuery(".checkout-passwordconf").after("<div class='details-field-error'>Please fill in the above field</div>");
				errors = 1;
			} else if (this.checkoutPasswordConf != this.checkoutPassword) {
				jQuery(".checkout-passwordconf").after("<div class='details-field-error'>This does not match the above password</div>");
				errors = 1;
			}

			if (this.autofill == false) {
				jQuery(".address-finder-form").after("<div class='field-error address-field-error'>Please select an address above, or enter a new one</div>");
				errors = 1;
			} else {
				if (!this.addressLine1 || !this.addressTown || !this.addressPostcode) {
					jQuery(".autofill-address").after("<div class='field-error address-field-error'>Please fill in the above fields to continue</div>");
					errors = 1;
				}
			}


			if (errors == 0) {
				jQuery(".cart-continue-button").css('opacity', '0.5');
				jQuery(".cart-continue-button").html("Loading");
				var address = { "AddressID": 1, "Name": "Postage Address", "Address1": this.addressLine1, "Address2": this.addressLine2, "City": this.addressTown, "PostCode": this.addressPostcode };
				var request = 'firstName=' + this.checkoutFirstname + '&surname=' + this.checkoutLastname + '&email=' + this.checkoutEmail + '&mobileTelNumber=' + this.checkoutPhone + '&password=' + this.checkoutPassword + '&daytimeTelNumber=' + this.checkoutPhone + '&packAddressString=' + JSON.stringify(address);
				this.ingrammicro.createRequest('POST', '/api/Registration/Register', request).subscribe(response => {
					if (response['IsValid'] == false) {
						this.showFormError = true;
						this.apiError = response['ValidationMessage'];
						console.log(response);
					} else {
						console.log(response);
						this.ingrammicro.createRequest('POST', `/api/Registration/GetCustomer`, "username=" + this.checkoutEmail + "&password=" + this.checkoutPassword).subscribe(response => {
							this.isLoggedIn = true;
							this.loggedInUser = response;
							localStorage.setItem('user', JSON.stringify(response));
							this.router.navigateByUrl('/cart/payment');
						});				
					}
					jQuery(".cart-continue-button").css('opacity', '1');
					jQuery(".cart-continue-button").html("Continue");
				});

			} else {
				console.log('errors')
      		}

		} else {
			if (this.autofill == true) {
				// fill in address
				if (!this.addressLine1 || !this.addressTown || !this.addressPostcode) {
					jQuery(".autofill-address").after("<div class='field-error address-field-error'>Please fill in the above fields to continue</div>");
				} else {
					console.log("time to add new address");
					var request = "Name=Address&Address1=" + this.addressLine1 + "&Address2=" + this.addressLine2 + "&City=" + this.addressTown + "&Postcode=" + this.addressPostcode;
					this.ingrammicro.createRequest('POST', '/api/Registration/ChangePackAddress?customerID=' + this.loggedInUser['UserID'] + '&email='+ this.loggedInUser['Email'], request).subscribe(response => {
						var newUserAddress = { "line1": this.addressLine1, "line2": this.addressLine2, "town": this.addressTown, "postcode": this.addressPostcode };
						localStorage.setItem('newAddress', JSON.stringify(newUserAddress));
						this.router.navigateByUrl('/cart/payment');
					});

				}
			} else {
				// default account details
				this.router.navigateByUrl('/cart/payment');
			}
		}
	}
}
