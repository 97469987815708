import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptcha2Component } from 'ngx-captcha';
import { IngrammicroService } from 'src/app/ingrammicro.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {

  showForm: Boolean = true;
  password: String = null;
  passwordverify: String = null;
  token: String = null;
  showVerifyError: Boolean = false;
  showMissingPassword: Boolean = false;
  showSuccess: Boolean = false;
  showFailure: Boolean = false;
  showCaptchaValidated: boolean = false;
  captchaValidationSiteKey: string = "";
  captchaSuccessToken: string;
  aFormGroup: FormGroup;

  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;
    
  constructor(private route: ActivatedRoute, private router: Router, private ingrammicro: IngrammicroService, private formBuilder: FormBuilder) { 
    this.captchaValidationSiteKey = ingrammicro.captchaValidationSiteKey
  }
   
  ngOnInit() {
      this.aFormGroup = this.formBuilder.group({
        recaptcha: ['', Validators.required]
      });
  
       this.token = this.route.snapshot.params['token'];
      if(localStorage.getItem('recaptcha')) this.captchaSuccessToken = localStorage.getItem('recaptcha');
  }

  onRecaptchaSuccess(e){
    this.showCaptchaValidated = true;
    this.captchaSuccessToken = e;
    localStorage.setItem('recaptcha', this.captchaSuccessToken);
    localStorage.setItem('recaptcha_expiry', Date.now() + (30 * 60 * 1000) + ''); 
  }

  onReCaptchaReset() {
    this.captchaElem.reloadCaptcha();
    this.showCaptchaValidated = false;
    this.captchaSuccessToken = undefined;
  }

  confirmPassword(){
    this.showVerifyError = false;
    this.showMissingPassword = false;

    if(this.password != null && this.passwordverify != null){
      if(this.password === this.passwordverify){
        this.ingrammicro.createRequest('POST', '/api/Registration/PasswordReset', 'token='+this.token+'&Newpassword='+this.password).subscribe(response => {
          if(response == true){
            this.showForm = false;
            this.showSuccess = true;
          } else {
            this.showForm = false;
            this.showFailure = true;
          }
        });
      } else {
        this.showVerifyError = true;
      }
    } else {
      this.showMissingPassword = true;
    }
  }

  navigateHome(){
    this.router.navigateByUrl('/');
  }
}
