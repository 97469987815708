import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { style, animate, transition, trigger } from '@angular/animations';

import * as jQuery from 'jquery';
import { IngrammicroService } from '../ingrammicro.service';
import { HeaderService } from '../global/header/header.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';
import { RecaptchaService } from '../recaptcha.service';
import { Location } from '@angular/common';
import { ImeiService } from '../imei.service';
declare var require: any;

@Component({
	selector: 'app-viewtablet',
	templateUrl: './viewtablet.component.html',
	styleUrls: ['./viewtablet.component.scss'],
	animations: [
		trigger('fadeInOut', [
			transition(':leave', [   // :leave is alias to '* => void'
				animate(600, style({ opacity: 0 }))
			]),
			transition(':enter', [   // :enter is alias to 'void => *'
				style({ opacity: 0 }),
				animate('600ms 600ms ease-out', style({ opacity: 1 }))
			])
		]),
		trigger('fadeIn', [
			transition(':leave', [   // :leave is alias to '* => void'
				animate(600, style({ opacity: 0 }))
			]),
			transition(':enter', [   // :enter is alias to 'void => *'     
				style({ opacity: 0, position: 'absolute' }),
				animate('600ms 900ms ease-out', style({ opacity: 1, position: 'relative' }))
			])

		]),
		trigger('fadeOut', [
			transition(':leave', [   // :leave is alias to '* => void'
				animate(600, style({ opacity: 0 }))
			])

		])
	]
})
export class ViewtabletComponent implements OnInit {

	showIntroduction: boolean = true;
	showInitialPrice: boolean = true;
	showImei: boolean = false;
	showCheck: boolean = false;
	showRecaptcha: boolean = false;
	showFmiLock: boolean = false;
	showFmiNotice: boolean = false;
	showPrice: boolean = false;
	showQuoteButton: boolean = false;
	showInvalidDevice: boolean = false;
	showInvalidIMEI: boolean = false;
	showFmiHelp: boolean = false;

	deviceType: String = null;

	imeiInput: String = null;

	tabletId: Number = null;
	imei: String = null;
	cartContents: Array<Object> = [];
	basketId: String = null;
	tabletPrice: number = null;
	tablet: Object = {};
	FmiLock: boolean = false;
	fmi: boolean = false;
	questions: Array<any> = [];
	numberOfQuestions: Number = null;
	tempAnswers: Array<any> = [];
	answers: Array<any> = [];
	answersPretty: Array<any> = [];

	failedFmiQuote: boolean = false;
	hasGoogleAccount: boolean = false;

	fmiNotice: String = null;

	invalidDeviceName: String = null;
	invalidDeviceUrl: string = null;

	showCaptchaValidated: boolean = false;
	captchaValidationSiteKey: string = "";
	captchaSuccessToken: string;
  
	aFormGroup: FormGroup;
  
	@ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;
  
	onRecaptchaSuccess(e){
		this.showCaptchaValidated = true;
		this.captchaSuccessToken = e;
		localStorage.setItem('recaptcha', this.captchaSuccessToken);
        localStorage.setItem('recaptcha_expiry', Date.now() + (30 * 60 * 1000) + '');
	}

	constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute, private ingrammicro: IngrammicroService, private headerService: HeaderService, private formBuilder: FormBuilder, private recaptchaService: RecaptchaService, public location: Location) {
		this.captchaValidationSiteKey = ingrammicro.captchaValidationSiteKey
	}
	
	ngOnInit() {
		this.route.params.subscribe(param => {
			this.tabletId = param['id'];
			this.imei = ImeiService.imei

			this.aFormGroup = this.formBuilder.group({
				recaptcha: ['', Validators.required]
			});
	
			this.headerService.setShowHeader(true);
	
			document.querySelector('body').style.overflow = 'hidden';
			setTimeout(() => {
				document.querySelector('body').style.overflow = 'auto';
			}, 3000);
	
	
			this.ingrammicro.createRequest('GET', '/api/Model/GetModelbyModelID?ModelID=' + this.tabletId).subscribe(response => {
				this.tablet = response[0];
			});
	
			const Odometer = require('../../assets/js/odometer.js');
			var el = document.querySelector('.odometer');
	
			var od = new Odometer({
				el: el,
				value: 333555,
			});
	
			if (localStorage.getItem("cart")) {
				this.cartContents = JSON.parse(localStorage.getItem("cart"));
			}
	
			if(localStorage.getItem('recaptcha')) {
				this.captchaSuccessToken = localStorage.getItem('recaptcha');
				setTimeout(() => {
					this.performIntro();
				}, 3000);
			} else {
				setTimeout(() => {
					this.showInitialPrice = false;
					this.showRecaptcha = true;
				}, 3000);
			}
		})
	}

	performIntro() {
		this.ingrammicro.createRequest('GET', '/api/PurchaseCriteria/GetPurchaseCriteria?ModelNo=' + this.tabletId).subscribe(response => {
			this.questions = response;
			this.numberOfQuestions = Object.keys(this.questions).length;
		});
		
		this.showInitialPrice = false;
		this.showCheck = true;

		if(this.tablet['Manufacturer'] == "Apple"){
			if (this.imei) {

				this.ingrammicro.createRequest('POST', `/api/IMEI/LookupFMIIMEI?IMEI=${this.imei}`, "").subscribe(response => {
					if (response == "0") { // Unlocked
						jQuery('#answer-18-51').click().click();
						jQuery('#answer-18-51').css({ "opacity": "0.5", "pointer-events": "none" });
						jQuery('.phone-view-select').first().css("pointer-events", "none");

						setTimeout(() => {
							this.showIntroduction = false;
							this.showCheck = false;
						}, 4800);
					} else if (response == "1") { // Locked
						jQuery('.phone-view-select').first().addClass('fmi-enabled');
						jQuery('#answer-18-52').click().click();
						jQuery('#answer-18-52').css({ "opacity": "0.5", "pointer-events": "none" });
						jQuery('.phone-view-select').first().css("pointer-events", "none");

						setTimeout(() => {
							this.showIntroduction = false;
							this.showFmiHelp = true;
						}, 4800);
					} else {
						setTimeout(() => {
							this.showIntroduction = false;
						}, 4800);
					}
				});
				
			} else {
				this.showCheck = false;
				this.showImei = true;
			}
		}  else {
			this.showIntroduction = false;
		}
	}

	testFMI(){
		this.showImei = false;
		this.showCheck = true;
		document.querySelector('body').style.overflow = "hidden";

		this.imei = this.imeiInput;
		if (this.imei) {	
			this.ingrammicro.createRequest('POST', `/api/IMEI/LookupFMIIMEI?IMEI=${this.imei}`, "").subscribe(response => {
				if (response == "0") { // Unlocked
					jQuery('.phone-view-select').first().removeClass('fmi-enabled');
					jQuery('#answer-18-51').click().click();
					jQuery('#answer-18-51').css({ "opacity": "0.5", "pointer-events": "none" });
					jQuery('.phone-view-select').first().css("pointer-events", "none");
				} else if (response == "1") { // Locked
					jQuery('.phone-view-select').first().addClass('fmi-enabled');
					jQuery('#answer-18-52').click().click();
					jQuery('#answer-18-52').css({ "opacity": "0.5", "pointer-events": "none" });
					jQuery('.phone-view-select').first().css("pointer-events", "none");
					this.showFmiLock = true;
					this.fmiNotice = 'locked';
				}
			});

			this.ingrammicro.createRequest('POST', '/api/IMEI/LookupModelFromIMEI?IMEI=' + this.imei).subscribe(response => {
				if(response != null){
					if (response['ModelID'] == this.tabletId) {
						setTimeout(() => {
							this.showCheck = false;
							this.showIntroduction = false;

						}, 4800);
					} else {
						setTimeout(() => {
							this.showCheck = false;
							this.showInvalidDevice = true;
							this.invalidDeviceName = response['FullName'];

							if(response['ProductClassNo'] == 15 || response['ProductClassNo'] == 21){
								this.invalidDeviceUrl = "/viewtablet/" + response['FullNameURLFriendly'] + "/" + response['ModelID']+ "/" + 'imei';
							} else if(response['ProductClassNo'] == 1){
								this.invalidDeviceUrl = "/viewphone/" + response['FullNameURLFriendly'] + '/' + response['ModelID'] + "/" + 'imei';
							} else if(response['ProductClassNo'] == 24 || response['ProductClassNo'] == 29){
								this.invalidDeviceUrl = "/viewwearable/" + response['FullNameURLFriendly'] + '/' + response['ModelID'] + "/" + 'imei';
							} else {
								this.showInvalidDevice = false;
								this.showInvalidIMEI = true;
							}

						}, 4800);
					}
				} else {
					setTimeout(() => {
						this.showCheck = false;

						this.showInvalidDevice = true;
					}, 4800);
				}
			}, error => {
				this.showCheck = false;
				this.showInvalidIMEI = true;
			});		
			document.querySelector('body').style.overflow = "auto";
		} else {
			this.showCheck = false;
			this.showImei = true;
			// Please enter a valid 15 character IMEI number
			jQuery('.imei-number').css('background', '#fbb');
			jQuery('.imei-overlay-sub').html("Please enter a valid 15 character IMEI number").css({ 'color': 'red', 'font-weight': '400' });
			this.captchaElem.reloadCaptcha();
			this.showCaptchaValidated = false;
		}
	}

	routeToCorrectDevice(){
		this.router.navigateByUrl(this.invalidDeviceUrl);
		ImeiService.imei = this.imeiInput
		this.showInvalidDevice = false
	}

	returnToHome(){
		this.router.navigateByUrl('/');
	}

	retestFMI(){
		this.showIntroduction = true;
		this.showCheck = true;
		this.showPrice = false;
		this.showFmiHelp = false;

		document.querySelector('body').style.overflow = 'hidden';
		setTimeout(() => {
			document.querySelector('body').style.overflow = 'auto';
		}, 10000);

		window.scrollTo(0, 0);

		setTimeout(() => {
			this.ingrammicro.createRequest('POST', `/api/IMEI/LookupFMIIMEI?IMEI=${this.imei}`, "").subscribe(response => {
				if (response == "0") { // Unlocked
					jQuery('.phone-view-select').first().removeClass('fmi-enabled');
					jQuery('#answer-18-51').click().click();
					jQuery('#answer-18-51').css({ "opacity": "0.5", "pointer-events": "none" });
					jQuery('.phone-view-select').first().css("pointer-events", "none");
					this.fmiNotice = "unlocked";
				} else if (response == "1") { // Locked
					jQuery('.phone-view-select').first().addClass('fmi-enabled');
					jQuery('#answer-18-52').click().click();
					jQuery('#answer-18-52').css({ "opacity": "0.5", "pointer-events": "none" });
					jQuery('.phone-view-select').first().css("pointer-events", "none");
					this.fmiNotice = "locked";
					this.showFmiNotice = true;
				} else {
					this.showFmiNotice = false;
				}

				this.showCheck = false;
				setTimeout(() => {
					this.showFmiNotice = false;
					this.showIntroduction = false;
				}, 4800);
			});
		}, 4800);
	}

	ngOnDestroy() {
		const Odometer = null;
		document.querySelector('body').style.overflow = 'auto';
	}

	toggleSelect(event) {
		var element = event.target;
		jQuery(element).closest('.phone-view-select').toggleClass('active');
	}

	selectOption(event, questionId, answerId, questionName, answerName) {
		var element = event.target;
		jQuery(element).parent().find('.phone-view-select-answer').removeClass('active');
		jQuery(element).addClass('active');
		jQuery(element).parent().removeClass('not-answered');
		jQuery(element).parent().parent().parent().addClass('ticked-answer');
		jQuery(element).closest('.phone-view-question-outer').addClass('question-answered');
		this.tempAnswers[questionId] = [answerId, questionName, answerName];
		this.checkFields();
		if (questionId == 18 && answerId == 52) {
			jQuery('.fmi-lock-notice').fadeIn();
			this.fmi = true;
		} else if (questionId == 18 && answerId == 51) {
			jQuery('.fmi-lock-notice').css('display', 'none');
			this.fmi = false;
		}
	}

	selectNetwork(event, name, questionId, answerId, questionName) {
		var element = event.target;
		var selectedOption = name;
		var answerName = name;
		jQuery(element).closest('.phone-view-select').find('.phone-view-select-network').removeClass('active');
		jQuery(element).closest('.phone-view-select-network').addClass('active');
		jQuery(element).closest('.phone-view-select-answers').removeClass('not-answered');
		jQuery(element).closest('.phone-view-select').find('.phone-view-select-question').html(selectedOption);
		jQuery('.network-question').parent().find('.phone-view-select-question').append('<div class="ticked-question"></div>');
		jQuery('.network-question').parent().find('.phone-view-select-question').addClass('active');
		jQuery(element).closest('.phone-view-select').parent().addClass('ticked-network');
		jQuery(element).closest('.phone-view-question-outer').addClass('question-answered');
		this.tempAnswers[questionId] = [answerId, questionName, answerName];
		this.checkFields();
	}

	checkFields() { // If all dropdowns are filled
		if (jQuery('.phone-view-select-answers').hasClass('not-answered')) {
			console.log('Questions still remaining to be answered');
		} else {
			this.answers = [];
			this.answersPretty = [];
			for (var $i = 0; $i < this.tempAnswers.length; $i++) {
				if (this.tempAnswers[$i]) {
					this.answers.push({ "question": $i, "answer": this.tempAnswers[$i][0] });
					this.answersPretty.push({ "question": this.tempAnswers[$i][1], "answer": this.tempAnswers[$i][2] });
					// FMI & Google Account
					if ($i == 18) {
						if (this.tempAnswers[$i][0] == 52) {
							this.fmi = true;
						} else {
							this.fmi = false;
						}
					}
					if ($i == 19) {
						if (this.tempAnswers[$i][0] == 55) {
							this.hasGoogleAccount = true;
						} else {
							this.hasGoogleAccount = false;
						}
					}
				}
			}

			//this.showQuoteButton = true;

			this.calculatePrice();
		}
	}

	calculatePrice() {
		var request = [];
		this.tabletPrice = null;
		jQuery('.phone-view-price').css('opacity', '0');
		for (let question of this.answers) {
			if (question.question == 18) {
				if (question.answer == 52) {
					request.push({ "PurchaseCriteriaNo": 18, "PurchaseCriteriaValueNo": 52 });
					this.failedFmiQuote = true;
				} else {
					request.push({ "PurchaseCriteriaNo": question.question, "PurchaseCriteriaValueNo": question.answer });
					this.failedFmiQuote = false;
				}
			} else {
				request.push({ "PurchaseCriteriaNo": question.question, "PurchaseCriteriaValueNo": question.answer });
			}
		}

		var string = 'ModelId=' + this.tabletId + '&IMEI='+this.imei+'&VibrationPass=pass&SoundPass=pass&FrontCameraPass=pass&BackCameraPass=pass&ScreenAreaPass=pass&purchaseCriteriaResults=' + JSON.stringify(request);
		
		this.ingrammicro.createRequest('POST', '/api/Quotation/GetQuoteAfterDiagnostics', string).subscribe(response => {
			this.tabletPrice = response['TotalCost'];
			this.basketId = response['BasketID'];

			if (!jQuery('.ticked-answer').hasClass('active')) {
				this.showPrice = true;				

				if (this.tabletPrice > 0 && this.failedFmiQuote == false) {
					var angular = this;
					setTimeout(() => {
						jQuery('.odometer').appendTo('span.odometer-trigger');
						jQuery('.odometer').text(Number(this.tabletPrice) * 100);
					}, 500);
				} else {
					setTimeout(() => {
						jQuery('span.odometer-trigger').html("<span class='padding-box'>0.00</span>");
					}, 500);
				}
			}

			jQuery('.phone-view-price').css('opacity', '1');
		});

		//Now the price has been calculated, lets show it.
	}

	resetQuestions() {
		jQuery(".phone-view-select-answers").addClass("not-answered");
		jQuery(".phone-view-select-answer").removeClass("active");
		jQuery(".phone-view-select-network").removeClass("active");
		jQuery(".phone-view-select").removeClass("ticked-answer");
		jQuery(".phone-view-select").removeClass("ticked-network");
		jQuery(".phone-view-select-question .ticked-question").remove();
		jQuery(".network-question").closest('.phone-view-select').find('.phone-view-select-question').html("Please select your network");
		jQuery(".network-question").closest('.phone-view-select').find('.phone-view-select-question').css("font-weight", "700");
	}

	addToCart() {
		if (this.tabletPrice || this.tabletPrice === 0) {
			jQuery(".phone-view-add-button").css({ "opacity": "0.5", "pointer-events": "none", "cursor": "default" });
			jQuery(".phone-view-add-button").html("Loading");

			var addQty = 1;
			if (jQuery(".phone-view-add input").val() != "") {
				addQty = +jQuery(".phone-view-add input").val();
				if (!addQty) { addQty = 1; }
			}

			this.deviceType = "Phone";
			if(this.cartContents.length == 0){
				this.cartContents.push({ "phoneName": this.tablet['Model'], "phoneBrand": this.tablet['Manufacturer'], "phoneType": this.deviceType, "phoneImage": this.tablet['ImageURL'], "answers": this.answers, "prettyAnswers": this.answersPretty, "quantity": addQty, "price": this.tabletPrice, "basketId": this.basketId });
				localStorage.setItem('cart', JSON.stringify(this.cartContents));
				this.router.navigateByUrl('/cart');
			} else {
				this.cartContents = [];
				this.cartContents.push({ "phoneName": this.tablet['Model'], "phoneBrand": this.tablet['Manufacturer'], "phoneType": this.deviceType, "phoneImage": this.tablet['ImageURL'], "answers": this.answers, "prettyAnswers": this.answersPretty, "quantity": addQty, "price": this.tabletPrice, "basketId": this.basketId });
				localStorage.setItem('cart', JSON.stringify(this.cartContents));
				this.router.navigateByUrl('/cart');
			}
		}
	}

	closeEstimate() {
		setTimeout(() => {
			jQuery('.odometer').appendTo('.final-price-container');
		}, 500);
		this.showPrice = false;
		this.showQuoteButton = true;
	}

	declineQuote() {
		this.router.navigateByUrl('/');
	}

	closeFmiHelp(){
		this.showFmiHelp = false;
	}
}
