import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import * as jQuery from 'jquery';
import { HeaderService } from 'src/app/global/header/header.service';

@Component({
	selector: 'app-complete',
	templateUrl: './complete.component.html',
	styleUrls: ['../cart.component.scss', './complete.component.scss']
})
export class CompleteComponent implements OnInit {

	postageType: Number = 0;
	reference: Number = 0;

	constructor(private http: HttpClient, private headerService: HeaderService, private router: Router) { }

	ngOnInit() {

		this.headerService.setShowHeader(false);

		if (!localStorage.getItem("cart")) {
			this.router.navigateByUrl('/cart');
		}

		jQuery(document).scroll(function () {
			var scrolled = (jQuery(document).scrollTop() / 2);
			jQuery('.anim-banner').css('background-position', 'center ' + scrolled + 'px');
		});

		if (localStorage.getItem("postage")) {
			this.postageType = JSON.parse(localStorage.getItem("postage"));
		}

		if (localStorage.getItem("reference")) {
			this.reference = JSON.parse(localStorage.getItem("reference"));
		}


    localStorage.setItem('cart', "");
    localStorage.setItem('promo', "");
		localStorage.setItem('payment', "");
		localStorage.setItem('user', "");
		localStorage.setItem('postage', "");
		localStorage.setItem('newAddress', "");
		localStorage.setItem('reference', "");
	}

}
