import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as jQuery from 'jquery';
import { HeaderService } from 'src/app/global/header/header.service';
import { IngrammicroService } from 'src/app/ingrammicro.service';

@Component({
	selector: 'app-bankdetails',
	templateUrl: './bankdetails.component.html',
	styleUrls: ['../cart.component.scss', './bankdetails.component.scss']
})
export class BankdetailsComponent implements OnInit {

	cartContents: Array<any> = [];
	totalCheckout: number = null;
	bankName: String = null;
	bankNumber: String = null;
	bankSort: String = null;
	showValidationError: Boolean = false;
  loading: boolean = false;

  promoCode: string = '';
  promoValue: number = 0;

	constructor(private router: Router, private headerService: HeaderService, private ingrammicro: IngrammicroService) { }

	ngOnInit() {

		this.headerService.setShowHeader(false);

		if (!localStorage.getItem("cart")) {
			this.router.navigateByUrl('/cart');
    }

    if (localStorage.getItem("promo")) {
      this.promoValue = JSON.parse(localStorage.getItem("promo"))['value'];
      this.promoCode = JSON.parse(localStorage.getItem("promo"))['code'];
    }

		jQuery(document).scroll(function () {
			var scrolled = (jQuery(document).scrollTop() / 2);
			jQuery('.anim-banner').css('background-position', 'center ' + scrolled + 'px');
		});

		if (localStorage.getItem("cart")) {
			this.cartContents = JSON.parse(localStorage.getItem("cart"));

			for (let item of this.cartContents) {
				this.totalCheckout = this.totalCheckout + (item.quantity * item.price);
			}

		}
	}

	checkoutContinue() {
		this.showValidationError = false;
		this.loading = true;
		this.ingrammicro.createRequest('POST', '/api/validator/bankdetails', 'Accountnumber='+this.bankNumber+'&Sortcode='+this.bankSort).subscribe(response => {
			if(response == true){
				localStorage.setItem('payment', JSON.stringify({ "paymentType": 2, "AccountName": this.bankName, "AccountNumber": this.bankNumber, "AccountSort": this.bankSort }));
				this.router.navigateByUrl('/cart/delivery');
			} else {
				this.showValidationError = true;
			}
			this.loading = false;
		});
	}

}
