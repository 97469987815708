import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { SEOService } from '../seo.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  constructor(private titleService: Title, private meta: Meta, private seoService: SEOService) { }

  ngOnInit() {
    this.titleService.setTitle('Contact us | Three');
    this.seoService.createLinkForCanonicalURL();
  }

}
