import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-termscondtions',
  templateUrl: './termscondtions.component.html',
  styleUrls: ['./termscondtions.component.scss']
})
export class TermscondtionsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
