import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import * as jQuery from 'jquery';
import { IngrammicroService } from 'src/app/ingrammicro.service';
import { HeaderService } from '../header/header.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RecaptchaService } from 'src/app/recaptcha.service';

@Component({
	selector: 'app-loginpopup',
	templateUrl: './loginpopup.component.html',
	styleUrls: ['./loginpopup.component.scss']
})
export class LoginpopupComponent implements OnInit {

	loginUsername: String = null;
	loginPassword: String = null;
	fieldError: boolean = false;
	loading: boolean = false;

	showCaptchaValidated: boolean = false;
	captchaValidationSiteKey: string = "";
	captchaSuccessToken: string;
	aFormGroup: FormGroup;

	@ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;


	constructor(private http: HttpClient, private router: Router, private ingrammicro: IngrammicroService, private headerService: HeaderService, private formBuilder: FormBuilder, private recaptchaService: RecaptchaService) {
		this.captchaValidationSiteKey = ingrammicro.captchaValidationSiteKey
	}

	ngOnInit() {
		this.aFormGroup = this.formBuilder.group({
			recaptcha: ['', Validators.required]
		});
		
		if(localStorage.getItem('recaptcha')) this.captchaSuccessToken = localStorage.getItem('recaptcha');
	}

	onRecaptchaSuccess(e) {
		this.captchaSuccessToken = e;
		this.showCaptchaValidated = true;
		localStorage.setItem('recaptcha', this.captchaSuccessToken);
		localStorage.setItem('recaptcha_expiry', Date.now() + (30 * 60 * 1000) + '');
	}
	
	onReCaptchaReset() {
		if(this.captchaElem) this.captchaElem.reloadCaptcha();
		this.showCaptchaValidated = false;
		this.captchaSuccessToken = undefined;
	}

	login() {
		jQuery(".field-error").remove();
		jQuery(".login-button").css('opacity', '0.5');
		jQuery(".login-button").html("Loading");
		this.loading = true;
			this.ingrammicro.createRequest('POST', `/api/Registration/GetCustomer`, "username=" + this.loginUsername + "&password=" + this.loginPassword).subscribe(response => {
				this.loading = false;
				if (response['CustomerID'] > 0) {
					localStorage.setItem('user', JSON.stringify(response));
					this.router.navigateByUrl('/user/tradein');
					// this.onReCaptchaReset();
					this.headerService.isLoggedIn = true;
					this.closeLoginPopup();
				} else {
					this.fieldError = true;
					// this.onReCaptchaReset();
				}
				jQuery(".login-button").css('opacity', '1');
				jQuery(".login-button").html("Log In");
		})
	}

	closeLoginPopup() {
		jQuery('.loginpopup-overlay').fadeOut();
	}

	showPasswordReset(){
		this.closeLoginPopup();
		this.headerService.setShowPasswordReset(true);
	}
}
