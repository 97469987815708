import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable()
export class HeaderService {

    public showHeader = new BehaviorSubject('ShowHeader');
    public showPasswordReset = new BehaviorSubject('ShowPasswordReset');
    public isLoggedIn;
    public showAPIBlockPopup =  new BehaviorSubject(null);
 
    constructor() { }

    setShowHeader(showHeader){
        this.showHeader.next(showHeader);
    }

    setShowPasswordReset(showPasswordReset){
        this.showPasswordReset.next(showPasswordReset);
    }

    setShowAPIBlockPopup(showAPIBlockPopup) {
        this.showAPIBlockPopup.next(showAPIBlockPopup);
    }
}