import { AfterViewInit, Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Injectable } from '@angular/core';
import { ReCaptchaV3Service, ScriptService } from 'ngx-captcha';

declare var hljs: any;

@Injectable({
    providedIn: 'root'
})

export class RecaptchaService implements OnInit, AfterViewInit {

  public siteKey?: string = '6Ldb0nYgAAAAAAr25v3ihg5USZjXo-XdyWU1arvL';
  public action?: string = 'homepage';
  public token?: string;
  public error?: string;

  public readonly constructorCode = `
  import { ReCaptchaV3Service } from 'ngx-captcha';

  constructor(
    private reCaptchaV3Service: ReCaptchaV3Service
  ) { }
  `;

  public readonly methodCode = `
  this.reCaptchaV3Service.execute(this.siteKey, 'homepage', (token) => {
    console.log('This is your token: ', token);
  }, {
    useGlobalDomain: false // optional
  });
  `;

  constructor(
    private reCaptchaV3Service: ReCaptchaV3Service,
    private scriptService: ScriptService,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.highlight();
  }

  execute(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.token = undefined;

      if (!this.siteKey) {
        this.error = 'Site key is not set';
        return;
      }
  
      if (!this.action) {
        this.error = 'Action is not set';
        return;
      }
  
      // clean script to make sure siteKey is set correctly (because previous script could be incorrect)
      this.scriptService.cleanup();
  
      this.error = undefined;
  
      this.reCaptchaV3Service.execute(this.siteKey, 'reCaptcha3DemoPage', (token) => {
        this.token = token;
        console.warn(this.siteKey);
        
        console.log('Your token is: ', token);
        resolve(token);
      });
    })
  }

  private highlight(): void {
    const highlightBlocks = document.getElementsByTagName('code');
    for (let i = 0; i < highlightBlocks.length; i++) {
      const block = highlightBlocks[i];
      hljs.highlightBlock(block);
    }
  }
}