import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import * as jQuery from 'jquery';
import { HeaderService } from 'src/app/global/header/header.service';
import { IngrammicroService } from 'src/app/ingrammicro.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReCaptcha2Component } from 'ngx-captcha';
import { RecaptchaService } from 'src/app/recaptcha.service';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['../cart.component.scss', './login.component.scss']
})
export class LoginComponent implements OnInit {

	loginUsername: String = null;
	loginPassword: String = null;
	cartContents: Array<any> = [];
	totalCheckout: number = null;
	showFormError: boolean = false;
	loading: boolean = false;

	promoCode: string = '';
	promoValue: number = 0;

	showCaptchaValidated: boolean = false;
	captchaValidationSiteKey: string = "";
	captchaSuccessToken: string;
	aFormGroup: FormGroup;

	@ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;

	constructor(private http: HttpClient, private router: Router, private ingrammicro: IngrammicroService, private headerService: HeaderService, private formBuilder: FormBuilder, private recaptchaService: RecaptchaService) {
		this.captchaValidationSiteKey = ingrammicro.captchaValidationSiteKey
	}

	
	ngOnInit() {
		this.aFormGroup = this.formBuilder.group({
			recaptcha: ['', Validators.required]
		});

		this.headerService.setShowHeader(false);

		if (!localStorage.getItem("cart")) {
			this.router.navigateByUrl('/cart');
		}

		if (localStorage.getItem("cart")) {
			this.cartContents = JSON.parse(localStorage.getItem("cart"));
			for (let item of this.cartContents) {
				this.totalCheckout = this.totalCheckout + (item.quantity * item.price);
		}

		if(localStorage.getItem('recaptcha')) this.captchaSuccessToken = localStorage.getItem('recaptcha');
    }

    if (localStorage.getItem("promo")) {
      this.promoValue = JSON.parse(localStorage.getItem("promo"))['value'];
      this.promoCode = JSON.parse(localStorage.getItem("promo"))['code'];
    }

		jQuery(document).scroll(function () {
			var scrolled = (jQuery(document).scrollTop() / 2);
			jQuery('.anim-banner').css('background-position', 'center ' + scrolled + 'px');
		});
	}

	onRecaptchaSuccess(e) {
		this.captchaSuccessToken = e;
		this.showCaptchaValidated = true;
		localStorage.setItem('recaptcha', this.captchaSuccessToken);
		localStorage.setItem('recaptcha_expiry', Date.now() + (30 * 60 * 1000) + '');
	}
	
	onReCaptchaReset() {
		if(this.captchaElem) this.captchaElem.reloadCaptcha();
		this.showCaptchaValidated = false;
		this.captchaSuccessToken = undefined;
	}

	login() {
		this.loading = true;
			this.ingrammicro.createRequest('POST', `/api/Registration/GetCustomer`, "username=" + this.loginUsername + "&password=" + this.loginPassword).subscribe(response => {
				if (response['CustomerID'] > 0) {
					localStorage.setItem('user', JSON.stringify(response));
					this,this.headerService.isLoggedIn = true;
					this.router.navigateByUrl('/cart/details');
				} else {
					this.showFormError = true;
				}
				this.loading = false;
			});
	}

	showPasswordReset(){
		this.headerService.setShowPasswordReset(true);
	}
}
